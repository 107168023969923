export const gameProviderData = [
    {
      img: "/assets/img/footer-img/spons1.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons2.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons3.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons4.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons5.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons6.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons7.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons8.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons9.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons10.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons11.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons12.png",
      alt: "game providers",  
    },
    {
      img: "/assets/img/footer-img/spons13.png",
      alt: "game providers",  
    },
]