import React from "react";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
import { promotionData } from "../../data/promotion.data";

const Promotion = () => {

  const slidesPerView =
    window.innerWidth >= 992 ? 3 : window.innerWidth >= 768 ? 2 : 1; // Adjust breakpoints as needed

  return (
    <section className="mb-4">
      <div className="container-lg promotion__container">
        <div className="d-flex justify-content-between mb-3">
          <h3>Promotions</h3>
          <div className="d-flex align-items-center">
            <span className=" border-rounded icon-left ">
              <BiChevronLeft className="w-100 fs-5 " />
            </span>
            <span className=" border-rounded icon-left">
              <BiChevronRight className="w-100 fs-5 " />
            </span>
          </div>
        </div>
        {/* swiper */}
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={10}
          freeMode={true}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
        >
          {promotionData.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="card-container">
                <div class="card card-promotion">
                  <img src={item.img} class="card-img-top" alt={item.alt} />
                  <div class="card-body">
                    <h5 class="card-title custom-card-title">{item.title}</h5>
                    <p class="card-text custom-card-text">{item.text}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* swiper end */}
      </div>
    </section>
  );
};

export default Promotion;
