import React, { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { sidebarData } from "../../data/sidebar.data";
import {
  BiSolidChevronRight,
  BiSolidChevronLeft,
  BiMenuAltRight,
  BiSearch,
} from "react-icons/bi";

import { Layout, Menu, Button, theme, Tag } from "antd";
import { Outlet, Link } from "react-router-dom";
import MainHeader from "../header/MainHeader";
import FooterMain from "../footer/Footer";

const { Header, Sider, Content } = Layout;

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div
      style={{
        backgroundImage: "url('/assets/img/background.jpg')",
        height: "100vh",
        margin: "0",
        position: "absolute",
        zIndex: "4",
        top: "0",
        padding: "0",
        width: "100vw",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Layout
        style={{
          background: "transparent",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            boxShadow: "1px 0px 0px rgba(255, 255, 255, 0.15)",
            overflowY: "auto",
          }}
          className="sidebar-container"
        >
          <div
            className="demo-logo-vertical"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
              paddingTop: "20px",
              zIndex: 99,
            }}
          >
            <a href="https://bj88ph.live/af/WJUI2u09/clonesites">
              <img
                src={
                  collapsed
                    ? "/assets/img/logo-dark.png"
                    : "/assets/img/logo-with-name.png"
                }
                alt="logo-dark"
                width={collapsed ? 50 : 100}
                // className="pb-"
              />
            </a>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            className="sidebar-menu"
            defaultSelectedKeys={["1"]}
            items={sidebarData.map((item) => {
              return {
                key: item.key,
                icon: (
                  <img
                    src={item.icon}
                    style={{ color: "#fff" }}
                    width={20}
                    alt="images"
                  />
                ),
                label: (
                  <>
                    {item.link ? ( // Check if there is a link
                      <Link to={item.link} className="text-decoration-none">
                        {item.label}
                      </Link>
                    ) : (
                      item.label
                    )}
                    {item.tag && (
                      <Tag color={item.tagColor || "blue"}>{item.tag}</Tag>
                    )}
                  </>
                ),
              };
            })}
          />
        </Sider>

        <Layout style={{ background: "none" }}>
          <div
            style={{
              padding: 0,
              display: "flex",
              height: "80px",
              width: "auto",
              position: "sticky",
              backdropFilter: "blur(10px)",
              background: "transparent",
              backgroundColor: "rgba(46, 48, 73, 0.75)",
            }}
          >
            <Button
              type="text"
              icon={
                collapsed ? <BiSolidChevronRight /> : <BiSolidChevronLeft />
              }
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 35,
                height: 35,
                // backgroundColor: "none",
                borderRadius: "50%",

                color: "#fff",
                display: "grid",
                alignContent: "center",
                justifyContent: "center",
                margin: "auto 0 20px -18px ",
                backdropFilter: "blur(10px)",
                // padding: "0px ",
                backgroundColor: "rgba(46, 48, 73, 0.75)",
              }}
              className="sidebar-btn"
            />
            <div className="menu-mobile d-flex align-items-center justify-content-between w-100 d-block d-lg-none  z-5 ">
              <div className="ps-1">
                <BiMenuAltRight className="text-white fs-2 cursor-pointer" />
                <img
                  src={
                    collapsed
                      ? "/assets/img/logo-dark.png"
                      : "/assets/img/logo-with-name.png"
                  }
                  alt="logo-dark"
                  width={collapsed ? 50 : 100}
                  className="ps-1"
                />
              </div>
              <div>
                <BiSearch
                  style={{
                    color: " #fff",
                    fontSize: "22px",
                    marginRight: "12px",
                    cursor: "pointer",
                  }}
                />
                <span className=" me-4 ">
                  <img
                    src="https://www.s5.com/images/live-chat.png"
                    alt="livechat"
                    srcset=""
                    width={24}
                    height={24}
                  />
                </span>
              </div>
            </div>
            <MainHeader />
          </div>
          <Content
            style={{
              // margin: "0px 16px",
              padding: "24px 0",
              color: "#fff",
              height: "100%",
              overflowY: "auto",
              zIndex: "5",
              position: "relative",
            }}
          >
            <Outlet />
            <FooterMain />
          </Content>
          {/* <Header> */}
          <div className=" header-btn-right-mobile py-2">
            <div className="cta">
              <div className="btn-div-login">
                <button
                  type="button"
                  class="btn me-2 btn-login "
                  style={{
                    border: "1px solid rgba(255, 255, 255, 0.23)",
                    // padding: "5px 15px",
                    color: "#fff",
                    fontWeight: "700",
                  }}
                  onClick={() =>
                    (window.location.href =
                      "https://bj88ph.live/af/WJUI2u09/clonesites")
                  }
                >
                  Login
                </button>
              </div>
              <div className="btn-div-sign">
                <button
                  type="button"
                  class="btn btn-primary btn-signin"
                  style={{
                    color: "rgba(255, 255, 255, 1)",
                    backgroundColor: "rgba(0, 180, 216, 1)",
                    // color: "#fff",
                    fontWeight: "700",
                  }}
                  onClick={() =>
                    (window.location.href =
                      "https://bj88ph.live/af/WJUI2u09/clonesites")
                  }
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
          {/* </Header> */}
        </Layout>
      </Layout>
    </div>
  );
};

export default SideBar;
