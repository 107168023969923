import React from "react";
import Carousel from "../components/section/Carousel";
import Promotion from "../components/section/Promotion";
import GameList from "../components/section/GameList";
import Latest from "../components/section/Latest";

const Home = () => {
  return (
    <div className="container-lg  relative">
      <Carousel />
      <Promotion/>
      <GameList/>
      <Latest/>
    </div>
  );
};

export default Home;
