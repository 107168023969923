export const socmeadData= [
    {
        img: "/assets/img/footer-img/facebook.png",
        alt: "soc meadia",
    },
    {
        img: "/assets/img/footer-img/twitter.png",
        alt: "soc meadia",
    },
    {
        img: "/assets/img/footer-img/instagram.png",
        alt: "soc meadia",
    },
    {
        img: "/assets/img/footer-img/youtube.png",
        alt: "soc meadia",
    },
    {
        img: "/assets/img/footer-img/telegram.png",
        alt: "soc meadia",
    },
    {
        img: "/assets/img/footer-img/tiktok.png",
        alt: "soc meadia",
    },
]