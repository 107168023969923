export const promotionData = [
  {
    img: "assets/img/cp1.jpg",
    alt: "promotion 1",
    title: "75% bonus Para Sa 1st Deposit Mo",
    text: 'May additional 75% na bonus ka "pag nag-deposit ka sa S5 for the first time!',
  },
  {
    img: "assets/img/cp2.jpg",
    alt: "promotion 2",
    title: "50% Bonus Para Sa 2nd Deposit Mo",
    text: "May extra 50% na bonus ka &rsquo; pag nag-deposit ka sa S5 for the second time!",
  },
  {
    img: "assets/img/cp3.jpg",
    alt: "promotion 3",
    title: "50% Na Bonus Para Sa 3rd Deposit Mo",
    text: "May additional 50% na bonus ka 'pag nag-deposit ka sa S5 for the third time!",
  },
  {
    img: "assets/img/cp3.jpg",
    alt: "promotion 3",
    title: "25% Reload Bonus 2x Per Day - Up to ₱10,000",
    text: "Top Up Anytime, Get Free Spins TWICE A Day, pag nag-deposit ka sa S5 for the third time!    ",
  },
];
