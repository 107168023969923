import React from "react";
import { BiSearch } from "react-icons/bi";

const MainHeader = () => {
  return (
    <>
      <div
        className="container main-header"
        style={{ width: "100vw", margin: "auto " }}
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div class="input-group">
            <div class="form-outline d-flex align-items-center">
              <input
                type="search"
                id="form1"
                class="form-control place-text"
                style={{
                  height: "35px",
                  backgroundColor: "transparent",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  borderRadius: "8px",
                  paddingLeft: "35px",
                  boxShadow: "none",
                  color: "#fff",
                }}
                placeholder="Search Games"
              />
              <BiSearch
                style={{
                  color: " rgba(255, 255, 255, 0.5)",
                  position: "absolute",
                  left: "10px",
                  fontSize: "20px",
                }}
              />
            </div>
          </div>
          <div className="d-flex">
            <span className=" me-4 my-auto">
              <img
                src="https://www.s5.com/images/live-chat.png"
                alt="livechat"
                srcset=""
                width={24}
                height={24}
              />
            </span>
            <div className=" header-btn-right ">
              <div className="cta">
                <div className="btn-div-login">
                  <button
                    type="button"
                    class="btn me-2 btn-login "
                    style={{
                      border: "1px solid rgba(255, 255, 255, 0.23)",
                      // padding: "5px 15px",
                      color: "#fff",
                      fontWeight: "700",
                    }}
                    onClick={() =>
                      (window.location.href =
                        "https://bj88ph.live/af/WJUI2u09/clonesites")
                    }
                  >
                    Login
                  </button>
                </div>
                <div className="btn-div-sign">
                  <button
                    type="button"
                    class="btn btn-primary btn-signin"
                    style={{
                      color: "rgba(255, 255, 255, 1)",
                      backgroundColor: "rgba(0, 180, 216, 1)",
                      // color: "#fff",
                      fontWeight: "700",
                    }}
                    onClick={() =>
                      (window.location.href =
                        "https://bj88ph.live/af/WJUI2u09/clonesites")
                    }
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainHeader;
