export const footerLink = [
  {
    link: "/",
    text: "About Us",
  },
  {
    link: "/",
    text: "Terms and Conditions",
  },
  {
    link: "/",
    text: "Privacy",
  },
  {
    link: "/",
    text: "Responsible Gaming",
  },
  {
    link: "/",
    text: "Game Providers",
  },
  {
    link: "/",
    text: "Payment Options",
  },
  {
    link: "/",
    text: "Agents Programs",
  },
  {
    link: "/",
    text: "Contact Us",
  },
];
