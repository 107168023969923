import React from "react";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
import { liveDealerData } from "../../data/liveDealer.data";
const GameList = () => {
  const slidesPerView =
    window.innerWidth >= 992 ? 6 : window.innerWidth >= 768 ? 4 : 2; // Adjust breakpoints as needed

  return (
    <section className="gamelist-section ">
      {liveDealerData.map((item, index) => (
        <div className="container mb-4">
          <div className="d-flex justify-content-between mb-3">
            {/* display the name object */}
            <h3 className="text-truncate flex-wrap">{item.name}</h3>
            <div className="d-flex align-items-center">
              <span className="cursor-pointer">
                <a
                  href="https://bj88ph.live/af/WJUI2u09/clonesites"
                  className="text-white text-decoration-none"
                >
                  <h3 className="view-all">View All</h3>
                </a>
              </span>
              <span className=" border-rounded icon-left ">
                <BiChevronLeft className="w-100 fs-5 " />
              </span>
              <span className=" border-rounded icon-left">
                <BiChevronRight className="w-100 fs-5 " />
              </span>
            </div>
          </div>

          <div className="sticky-slider overflow-hidden">
            <div>
              <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={10}
                modules={[FreeMode, Pagination]}
                className="mySwiper"
                // width="100%"
              >
                {item.data.map((game, gameIndex) => (
                  <SwiperSlide key={gameIndex}>
                    <div
                      className="relative image-container"
                      style={{ position: "relative" }}
                    >
                      {/* display the img */}
                      <img
                        src={game.img}
                        alt="alt"
                        className=" middle-image rounded-3"
                        srcset=""
                      />
                      <div className="middle-hover-effect pt-3 pb-2 ps-3 relative">
                        <div className="text text-center d-flex flex-column justify-content-between">
                          <a
                            href="https://bj88ph.live/af/WJUI2u09/clonesites"
                            className="nav-link btn-navlink"
                          >
                            Demo
                          </a>
                          <div className="d-flex justify-content-center">
                            <a href="https://bj88ph.live/af/WJUI2u09/clonesites">
                              <img
                                src="/assets/img/play.svg"
                                alt="play icon"
                                width="44px"
                                srcset=""
                                className="d-flex justify-content-center"
                              />
                            </a>
                          </div>
                          <div className="title-game text-start">
                            {/* display the title */}
                            <div
                              className="title text-start overflow-hidden text-truncate text-capitalize"
                              style={{ width: "200px" }}
                            >
                              {game.title}
                            </div>
                            {/* display the secondtitle */}
                            <span className="text-secondary text-start text-uppercase">
                              {game.secondtitle}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default GameList;
