import React from "react";
import { footerLink } from "../../data/footerlink.data";
import { paymentMethod } from "../../data/paymentMethod.data";
import { socmeadData } from "../../data/socmed.data";
import { gameProviderData } from "../../data/gameProvider.data";

const FooterMain = () => {
  return (
    <footer>
      <div className="container mt-5">
        {/* {footerData[0].sponsorImg.map((sponsor, index) => ( */}
        <div className="row row-cols-6 justify-content-center  w-100">
          {/* start of mapping of footerData */}
          {gameProviderData.map((item, index) => (
            <div className="col sponsor__item my-auto ms-3 " key={index}>
              <a href="https://bj88ph.live/af/WJUI2u09/clonesites">
                <img
                  src={item.img}
                  alt={item.alt}
                  // height="28px "
                  // width="156px"
                  className="w-100"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                  srcset=""
                />
              </a>
            </div>
          ))}
          {/* // sponsorImg */}
        </div>
        {/* // ))} */}

        <div className="d-flex flex-wrap border-top border-bottom py-4 my-3">
          {/* // footerlink */}
          <div className="container d-flex flex-wrap justify-content-around  ">
            {footerLink.map((item, index) => (
              <div className="footerlink__item" key={index}>
                <a href={item.link} className="nav-link text-uppercase">
                  {item.text}
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="container">
          <div className="row row-cols-9 justify-content-center w-100">
            {/* payment method image */}
            {paymentMethod.map((item, index) => (
              <div className="col sponsor__item my-auto ms-3 px-3 ">
                <a href="https://bj88ph.live/af/WJUI2u09/clonesites">
                  <img
                    src={item.img}
                    alt={item.alt}
                    className="w-100"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                    srcset=""
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="container my-4 py-2 border-top border-bottom">
          <div className="text-center">
            <span>Follow us:</span>
            {socmeadData.map((item, index) => (
              <span>
                <img
                  src={item.img}
                  alt={item.alt}
                  width="67"
                  height="auto"
                  srcset=""
                />{" "}
              </span>
            ))}
          </div>
        </div>
        <div className="container">
          <div className="logos text-center py-3">
            <img
              src="/assets/img/footer-img/pagcor-logo.png"
              alt="pagcor"
              height="96px"
              width="120px"
            />
            <img
              src="/assets/img/footer-img/21-fun-logo.png"
              alt="pagcor"
              style={{ maxWidth: "100%" }}
              height="44px"
            />
          </div>

          <div className="text-center">
            <span> Copyright S5 Casino 2023</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterMain;
