export const liveDealerData = [
  {
    name: "Live Dealers And Table Games",
    data: [
      {
        id: "1",
        img: "/assets/img/gl-ld1.jpg",
        alt: "livedealer1",
        title: "XXXtreme lightning Baccarat",
        secondtitle: "Evolution",
      },
      {
        id: "2",
        img: "/assets/img/gl-ld2.jpg",
        alt: "livedealer2",
        title: "Prosperity Tree Baccarat",
        secondtitle: "Evolution",
      },
      {
        id: "3",
        img: "/assets/img/gl-ld3.jpg",
        alt: "livedealer3",
        title: "Crazy Time",
        secondtitle: "Evolution",
      },
      {
        id: "4",
        img: "/assets/img/gl-ld4.jpg",
        alt: "livedealer4",
        title: "Monopoly Live",
        secondtitle: "Evolution",
      },
      {
        id: "5",
        img: "/assets/img/gl-ld5.jpg",
        alt: "livedealer5",
        title: "First Person XXXtreme lightning Baccarat",
        secondtitle: "Evolution",
      },
      {
        id: "6",
        img: "/assets/img/gl-ld6.jpg",
        alt: "livedealer6",
        title: "Speed Baccarat At",
        secondtitle: "Evolution",
      },
      {
        id: "7",
        img: "/assets/img/gl-ld7.jpg",
        alt: "livedealer7",
        title: "No Commission Baccarat",
        secondtitle: "Evolution",
      },
    ],
  },
  {
    name: "Jackpots",
    data: [
      {
        id: "1",
        img: "/assets/img/gl-jk1.jpg",
        alt: "livedealer1",
        title: "777",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "2",
        img: "/assets/img/gl-jk2.jpg",
        alt: "livedealer2",
        title: "T-Rex",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "3",
        img: "/assets/img/gl-jk3.jpg",
        alt: "livedealer3",
        title: "Mighty Medusa",
        secondtitle: "HABANERO",
      },
      {
        id: "4",
        img: "/assets/img/gl-jk4.jpg",
        alt: "livedealer4",
        title: "Cleopatra's Gold",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "5",
        img: "/assets/img/gl-jk5.jpg",
        alt: "livedealer5",
        title: "Gangsters",
        secondtitle: "HABANERO",
      },
      {
        id: "7",
        img: "/assets/img/gl-jk6.jpg",
        alt: "livedealer7",
        title: "Achilles",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "7",
        img: "/assets/img/gl-jk7.jpg",
        alt: "livedealer7",
        title: "Aztec's Treasure",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "7",
        img: "/assets/img/gl-jk8.jpg",
        alt: "livedealer7",
        title: "PayDirt!",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "7",
        img: "/assets/img/gl-jk9.jpg",
        alt: "livedealer7",
        title: "Wicked Witch",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "7",
        img: "/assets/img/gl-jk10.jpg",
        alt: "livedealer7",
        title: "5 Lucky Lions",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "7",
        img: "/assets/img/gl-jk11.jpg",
        alt: "livedealer7",
        title: "12 Zodiacs",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "7",
        img: "/assets/img/gl-jk12.jpg",
        alt: "livedealer7",
        title: "Fa Cai Shen Deluxe",
        secondtitle: "Real Time Gaming",
      },
    ],
  },
  {
    name: "Featured Games",
    data: [
      {
        id: "1",
        img: "/assets/img/gl-fg1.jpg",
        alt: "livedealer1",
        title: "Speed Winner",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "2",
        img: "/assets/img/gl-fg2.jpg",
        alt: "livedealer2",
        title: "777 Strike",
        secondtitle: "Red tiger",
      },
      {
        id: "3",
        img: "/assets/img/gl-fg3.jpg",
        alt: "livedealer3",
        title: "Midas Fortune",
        secondtitle: "PGSoft",
      },
      {
        id: "4",
        img: "/assets/img/gl-fg4.jpg",
        alt: "livedealer4",
        title: "Reel Rush_R4",
        secondtitle: "netent",
      },
      {
        id: "5",
        img: "/assets/img/gl-fg5.jpg",
        alt: "livedealer5",
        title: "Alchemy gold",
        secondtitle: "PGSoft",
      },
      {
        id: "7",
        img: "/assets/img/gl-fg6.jpg",
        alt: "livedealer7",
        title: "bass boss",
        secondtitle: "red tiger",
      },
      {
        id: "7",
        img: "/assets/img/gl-fg7.jpg",
        alt: "livedealer7",
        title: "mahjong ways 2",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "7",
        img: "/assets/img/gl-fg8.jpg",
        alt: "livedealer7",
        title: "lantern luck",
        secondtitle: "habanero",
      },
      {
        id: "7",
        img: "/assets/img/gl-fg9.jpg",
        alt: "livedealer7",
        title: "happiest christmas tree",
        secondtitle: "habanero",
      },
    ],
  },
  {
    name: "₱1SO Games",
    data: [
      {
        id: "1",
        img: "/assets/img/gl-pg1.jpg",
        alt: "livedealer1",
        title: "Christmas Gift Rush",
        secondtitle: "habanero",
      },
      {
        id: "2",
        img: "/assets/img/gl-pg2.jpg",
        alt: "livedealer2",
        title: "magic oak",
        secondtitle: "habanero",
      },
      {
        id: "3",
        img: "/assets/img/gl-pg3.jpg",
        alt: "livedealer3",
        title: "goal mania",
        secondtitle: "ortiz gaming",
      },
      {
        id: "4",
        img: "/assets/img/gl-pg4.jpg",
        alt: "livedealer4",
        title: "Knockout football",
        secondtitle: "habanero",
      },
      {
        id: "5",
        img: "/assets/img/gl-pg5.jpg",
        alt: "livedealer5",
        title: "Hot hot halloween",
        secondtitle: "habanero",
      },
      {
        id: "6",
        img: "/assets/img/gl-pg6.jpg",
        alt: "livedealer7",
        title: "fenghuang",
        secondtitle: "habanero",
      },
      {
        id: "7",
        img: "/assets/img/gl-pg7.jpg",
        alt: "livedealer7",
        title: "keno",
        secondtitle: "Real Time Gaming",
      },
      {
        id: "8",
        img: "/assets/img/gl-pg8.jpg",
        alt: "livedealer7",
        title: "lucky lucky",
        secondtitle: "habanero",
      },
      {
        id: "9",
        img: "/assets/img/gl-fg9.jpg",
        alt: "livedealer7",
        title: "ocean's call",
        secondtitle: "habanero",
      },
    ],
  },
  {
    name: "New Games",
    data: [
      {
        id: "1",
        img: "/assets/img/gl-ng1.jpg",
        alt: "livedealer1",
        title: "Ninja Raccoon Frenzy",
        secondtitle: "pg soft",
      },
      {
        id: "2",
        img: "/assets/img/gl-ng2.jpg",
        alt: "livedealer2",
        title: "Bikini Island Deluxe",
        secondtitle: "habanero",
      },
      {
        id: "3",
        img: "/assets/img/gl-ng3.jpg",
        alt: "livedealer3",
        title: "Gladiator's Glory",
        secondtitle: "pg soft",
      },
      {
        id: "4",
        img: "/assets/img/gl-ng4.jpg",
        alt: "livedealer4",
        title: "Safari Wilds",
        secondtitle: "pg soft",
      },
      {
        id: "5",
        img: "/assets/img/gl-ng5.jpg",
        alt: "livedealer5",
        title: "atomic kittens",
        secondtitle: "habanero",
      },
      {
        id: "6",
        img: "/assets/img/gl-ng6.jpg",
        alt: "livedealer7",
        title: "Color game",
        secondtitle: "king maker",
      },
      {
        id: "7",
        img: "/assets/img/gl-ng7.jpg",
        alt: "livedealer7",
        title: "minesweeper",
        secondtitle: "pgsoft",
      },
      {
        id: "8",
        img: "/assets/img/gl-ng8.jpg",
        alt: "livedealer7",
        title: "Bingo roll",
        secondtitle: "king maker",
      },
      {
        id: "9",
        img: "/assets/img/gl-ng9.jpg",
        alt: "livedealer7",
        title: "fruit candy",
        secondtitle: "pgsoft",
      },
    ],
  },
  {
    name: "Slots",
    data: [
      {
        id: "1",
        img: "/assets/img/slot/gl-s1.jpg",
        alt: "livedealer1",
        title: "Dreams of Macau",
        secondtitle: "pg soft",
      },
      {
        id: "2",
        img: "/assets/img/slot/gl-s2.jpg",
        alt: "livedealer2",
        title: "True Grit Redemption",
        secondtitle: "pg soft",
      },
      {
        id: "3",
        img: "/assets/img/slot/gl-s3.jpg",
        alt: "livedealer3",
        title: "Double Fortune",
        secondtitle: "pg soft",
      },
      {
        id: "4",
        img: "/assets/img/slot/gl-s4.jpg",
        alt: "livedealer4",
        title: "Dreams of Macau",
        secondtitle: "pg soft",
      },
      {
        id: "5",
        img: "/assets/img/slot/gl-s5.jpg",
        alt: "livedealer5",
        title: "Egypt's Book of Mystery",
        secondtitle: "pg soft",
      },
      {
        id: "6",
        img: "/assets/img/slot/gl-s6.jpg",
        alt: "livedealer7",
        title: "Double Fortune",
        secondtitle: "pg soft",
      },
      {
        id: "7",
        img: "/assets/img/slot/gl-s7.jpg",
        alt: "livedealer7",
        title: "Caishen Wins",
        secondtitle: "pgsoft",
      },
      {
        id: "8",
        img: "/assets/img/slot/gl-s8.jpg",
        alt: "livedealer7",
        title: "Crypto Gold",
        secondtitle: "pg soft",
      },
      {
        id: "9",
        img: "/assets/img/slot/gl-s9.jpg",
        alt: "livedealer7",
        title: "Phoenix Rises",
        secondtitle: "pgsoft",
      },
    ],
  },
  {
    name: "E-Bingo",
    data: [
      {
        id: "1",
        img: "/assets/img/e-bingo/gl-eb1.jpeg",
        alt: "livedealer1",
        title: "Gambeta Bingo",
        secondtitle: "ortiz gaming",
      },
      {
        id: "2",
        img: "/assets/img/e-bingo/gl-eb2.jpeg",
        alt: "livedealer2",
        title: "Bubble Mania",
        secondtitle: "ortiz gaming",
      },
      {
        id: "3",
        img: "/assets/img/e-bingo/gl-eb3.jpeg",
        alt: "livedealer3",
        title: "Dulce Mania",
        secondtitle: "ortiz gaming",
      },
      {
        id: "4",
        img: "/assets/img/e-bingo/gl-eb4.jpg",
        alt: "livedealer4",
        title: "Goal Mania",
        secondtitle: "ortiz gaming",
      },
      {
        id: "5",
        img: "/assets/img/e-bingo/gl-eb5.jpeg",
        alt: "livedealer5",
        title: "Ole Bingo",
        secondtitle: "ortiz gaming",
      },
      {
        id: "6",
        img: "/assets/img/e-bingo/gl-eb6.jpeg",
        alt: "livedealer7",
        title: "Six Bingo",
        secondtitle: "ortiz gaming",
      },
      {
        id: "7",
        img: "/assets/img/e-bingo/gl-eb7.jpeg",
        alt: "livedealer7",
        title: "Beach Bingo",
        secondtitle: "ortiz gaming",
      },
      {
        id: "8",
        img: "/assets/img/e-bingo/gl-eb8.jpeg",
        alt: "livedealer7",
        title: "Bingole",
        secondtitle: "ortiz gaming",
      },
      {
        id: "9",
        img: "/assets/img/e-bingo/gl-eb9.jpeg",
        alt: "livedealer7",
        title: "Bingol",
        secondtitle: "ortiz gaming",
      },
    ],
  },
];
