// import jackpot from "../assets/img/icons8-jackpot-64 (1).png";
export const sidebarData = [
  {
    key: "1",
    label: "Jackpot",
    icon: "/assets/img/icons8-jackpot-66.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "2",
    label: "Slots",
    icon: "/assets/img/icons8-slot-64 (1).png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "3",
    label: "Live Dealers",
    icon: "assets/img/icons8-video-24.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "4",
    label: "RTG Games",
    icon: "assets/img/icons8-cards-48.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
    alt: "games",
  },
  {
    key: "5",
    label: "Fishing Games",
    icon: "assets/img/icons8-fishing-60.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "6",
    label: "Table Games",
    icon: "assets/img/icons8-cards-60.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "7",
    label: "E-Bingo",
    icon: "assets/img/icons8-lotto-100.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "8",
    label: "All Games",
    icon: "assets/img/icons8-four-squares-96.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "9",
    label: "Sports Betting",
    icon: "assets/img/icons8-soccer-ball-100.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "10",
    label: "Promotions",
    icon: "assets/img/icons8-gift-90.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "11",
    label: "News",
    icon: "assets/img/icons8-news-99.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "12",
    label: "Cashier",
    icon: "assets/img/icons8-cashier-64.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
  {
    key: "13",
    label: "Get Android App",
    icon: "assets/img/icons8-download-96.png",
    link: "https://bj88ph.live/af/WJUI2u09/clonesites",
  },
];
