export const  paymentMethod = [
    {
      img: "/assets/img/footer-img/payment-img/pm1.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm2.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm3.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm4.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm5.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm6.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm7.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm8.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm9.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm10.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm11.png",
      alt: "images payment method",
    },
    {
      img: "/assets/img/footer-img/payment-img/pm12.png",
      alt: "images payment method",
    },
  ]