import React from "react";

const Latest = () => {
  return (
    <section>
      <div className="container">
        <div className="title my-2 mb-3">
          <h3>Latest Bets</h3>
        </div>
        <div className="table table-rounded ">
          <table width={"100%"} className="bg-none">
            <thead className="">
              <tr className="table-cell-head">
                <th className="table-cell-head">Player Name</th>
                <th className="table-cell-head">Game Name</th>
                <th className="table-cell-head">Bet Amount</th>
                <th className="table-cell-head">Profit Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Johnfort****</td>
                <td>Big Small</td>
                <td>₱ 166.00</td>
                <td className="text-danger">-₱146.00</td>
              </tr>
              <tr>
                <td>Johnfort****</td>
                <td>London Hunter</td>
                <td>₱ 16.00</td>
                <td className="text-success">₱146.00</td>
              </tr>
              <tr>
                <td>Johnfort****</td>
                <td>Lucky Lucky</td>
                <td>₱ 126.00</td>
                <td className="text-success">₱176.00</td>
              </tr>
              <tr>
                <td>Johnfort****</td>
                <td>Ruffled Cards</td>
                <td>₱ 46.00</td>
                <td className="text-danger">-₱146.00</td>
              </tr>
              <tr>
                <td>Johnfort****</td>
                <td>The Dead Escape</td>
                <td>₱ 76.00</td>
                <td className="text-success">₱126.00</td>
              </tr>
              <tr>
                <td>Johnfort****</td>
                <td>Ways of Play</td>
                <td>₱ 199.00</td>
                <td className="text-danger">-₱146.00</td>
              </tr>
         
           
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Latest;
