export const carouselData = [
  {
    img: "/assets/img/1.jpg",
    alt: "1",
  },
  {
    img: "/assets/img/2.jpg",
    alt: "2",
  },
  {
    img: "/assets/img/3.jpg",
    alt: "3",
  },
  {
    img: "/assets/img/4.jpg",
    alt: "4",
  },
  {
    img: "/assets/img/5.jpg",
    alt: "5",
  },
  {
    img: "/assets/img/6.jpg",
    alt: "6",
  },
  {
    img: "/assets/img/7.jpg",
    alt: "7",
  },
  {
    img: "/assets/img/8.jpg",
    alt: "8",
  },
  {
    img: "/assets/img/9.jpg",
    alt: "9",
  },
  {
    img: "/assets/img/10.jpg",
    alt: "10",
  },
  {
    img: "/assets/img/11.jpg",
    alt: "11",
  },
];
